import React from 'react'
import s from './styles.module.scss'
import cx from 'classnames'
const MtnSection = ({ children, color, img, small, className }) => {
  return (
    <section
      className={cx(s.section, small && s.small, img && s.img, s[color], className)}>
      {children}
      {img && <img src={img} className={s.border} alt="" />}
    </section>
  )
}

export default MtnSection
