import { useMutation } from '@apollo/client'
import gql from 'graphql-tag.macro'
import mapErrors from 'api/auth/mapErrors'
import useEvent from 'elements/analytics/useEvent'
const mutation = gql`
  mutation InsertWaitlist($objects: [waitlist_insert_input!]!) {
    insert_waitlist(objects: $objects) {
      returning {
        number
        code
        email
      }
    }
  }
`

const useInsertWaitlist = ({ onCompleted, ...options }) => {
  const event = useEvent()
  const [call, { data, error }] = useMutation(mutation, {
    ...options,
    onCompleted: data => {
      if (onCompleted) onCompleted(data.insert_waitlist.returning[0])
      event('joined beta list', { label: 'GOAL' })
    },
    onError: e => {
      //
    },
    context: { route: 'public' },
  })
  return {
    call: variables => call({ variables: { objects: variables } }),
    data: data ? data.insert_waitlist.returning[0] : null,
    error: mapErrors(error),
  }
}

export default useInsertWaitlist
