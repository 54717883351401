import { React, H1, B, Icon, Box, Row, Column, Mini, Header, P } from 'library'
import s from './styles.module.scss'
import Nav from 'components/Nav'
import Site from 'components/Site'
import cx from 'classnames'
import BetaRequest from 'components/BetaRequest'
import Footer from 'components/Footer'
import Symbol from 'components/logos/Symbol'
import iphone from './img/iphone.png'
import chat from './img/chat.svg'
import fire from './img/fire.svg'
import chart from './img/chart.svg'
import check from './img/check.svg'
import MtnSection from 'components/MtnSection'
import mountain1 from './img/mountain1.svg'
import mountain3 from './img/mountain3.svg'

const Home = ({ loggedOut }) => {
  return (
    <Site
      className={s.page}
      footer={<Footer />}
      banner={loggedOut ? 'You are logged out.' : null}>
      <MtnSection img={mountain3} className={s.hero}>
        <Column>
          <Header
            className={s.header}
            menu={
              <Nav>
                <H1 className={s.h1}>
                  A messaging app made just for cannabis cultivation.
                </H1>
              </Nav>
            }></Header>
          <Row className={s.row}>
            <Box className={cx(s.card, s.chat)}>
              <B className={s.dots}>::</B>
              <Icon src={chat} className={s.icon} alt="Chat Icon" />
              <B>Faster communication</B>
              <Mini className={s.tag}>Team Chat</Mini>
            </Box>

            <Box className={cx(s.card, s.task)}>
              <B className={s.dots}>::</B>
              <Icon src={check} className={s.icon} alt="Check Mark Icon" />
              <B>Share progress</B>
              <Mini className={s.tag}>Workflows</Mini>
            </Box>
            <img src={iphone} className={s.phone} alt="" />
            <Box className={cx(s.card, s.issue)}>
              <B className={s.dots}>::</B>
              <Icon src={fire} className={s.icon} alt="Issue Icon" />
              <B>Put fires out</B>
              <Mini className={s.tag}>Issue Tracking</Mini>
            </Box>
            <Box className={cx(s.card, s.data)}>
              <B className={s.dots}>::</B>
              <Icon src={chart} className={s.icon} alt="Chart Icon" />
              <B>Batch insights</B>
              <Mini className={s.tag}>Analytics</Mini>
            </Box>
          </Row>
        </Column>
      </MtnSection>
      <MtnSection img={mountain1} color="blue">
        <Column size="small" id="about">
          <P>
            <span className={s.bgWhite}>
              Scaling cannabis cultivation is hard.
            </span>{' '}
            We’ve been there. From the Emerald Triangle to the early days of
            Colorado and new cannabis programs across the nation.{' '}
            <B>How do we, as growers, retain our craft and quality,</B> while
            increasing output and consistency? All while training teams, putting
            out fires, and running a cost-efficient, profitable business? When
            every little problem adds up, wastes time, and costs money?
          </P>
          <br />
          <P>
            {/* This is why we built Fire Tower, to help you simplify and organize. */}
          </P>
          <P>
            We also know software. It’s why we
            built Fire Tower as a messaging app. The best tools fit the way you
            already work. If it takes more time to manage your work, you’ll only
            use it if forced to (see all compliance software). You need tools you can use on your
            feet, with sticky fingers, <i>that bring your team together</i>.
          </P>
          <br />
          <P>
            It's our mission to empower growers to run productive, efficient gardens. We believe healthy teams grow
            healthy plants. And healthy plants make happy customers—and your
            business prosper.
          </P>
        </Column>
      </MtnSection>
      <MtnSection color="lightBlue">
        <Column box className={s.waitlist}>
          <BetaRequest />
          <Symbol className={s.symbol} />
        </Column>
      </MtnSection>
    </Site>
  )
}

Home.propTypes = {}

export default Home
