const mapErrors = error => {
  // TODO: make this better, is a total hack

  if (!error) return
  let e = error.graphQLErrors || error.networkError || {}
  if (!e || (Array.isArray(e) && !e.length)) e = error.networkError // add .result?

  if (e.errors) e = e.errors

  if (Array.isArray(e) && e.length && e[0].param) {
    return { name: e[0].param, error: e[0].msg }
  }

  if (e.result) return { name: e.result.key, error: e.result.error }

  // this is generic catch all, only for login
  return { name: 'email', error: 'Hmm, something went wrong.' }
}

export default mapErrors
