import React, { useState, useEffect } from 'react'
import Form from './Form'
import { H1, H2 } from 'library'
import s from './styles.module.scss'
import useInsertWaitlist from 'api/waitlist/useInsertWaitlist'
import store from 'elements/store/localStorage'
import { useParams } from 'react-router-dom'

const BetaRequest = () => {
  const [success, setSuccess] = useState(() => store.get('list', true))
  const { code } = useParams()
  const { call, data, error } = useInsertWaitlist({
    onCompleted: data => {
      store.set('list', data, true)
    },
  })
  const use = code ? { codeUsed: code?.toUpperCase() } : null

  useEffect(() => {
    if (data) setSuccess(data)
  }, [data])
  if (success?.number)
    return (
      <div className={s.words} id="waitlist">
        <H1>
          You're <b>#{success.number}</b> on the waitlist.
        </H1>
        <H2>
          We'll send <b>{success.email}</b> an email when your access becomes
          available.
        </H2>
        <H2>
          Want to get in sooner? Here's your referral link:{' '}
          <b>https://www.firetower.us/r/{success?.code?.toLowerCase()}</b>{' '}
        </H2>
        <H2>
          When someone signs up with your link, we'll move you up the list.
          Note: because we verify signups, the number shown above may not
          immediately reflect changes in your waitlist position.
        </H2>
      </div>
    )
  return (
    <>
      <H1>Get early access.</H1>
      <H2>Enter your email to reserve your spot in Fire Tower's beta.</H2>
      <Form onSubmit={call} data={use} error={error} className={s.form} />
    </>
  )
}

export default BetaRequest
