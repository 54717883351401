import { React, PropTypes, TwoColumn, Column, Mini, Link, H2 } from 'library'
import Logo from '../logos/Wordmark/blue'
import cx from 'classnames'

import s from './styles.module.scss'

const Footer = ({ hideBlogLink, className }) => {
  return (
    <footer className={cx(s.footer, className)}>
      {!hideBlogLink && (
        <Column box className={s.blog}>
          <H2 darkMode>
            Read up on our blog,{' '}
            <Link to="/smokesignals">Smoke Signals &rarr;</Link>
          </H2>
        </Column>
      )}
      <div className={s.hr} />
      <TwoColumn
        className={s.bottom}
        right={
          <Mini>
            Questions? Email <b>friends@firetower.us</b>
          </Mini>
        }>
        <Logo />

        <div className={s.legal}>
          <Mini>© Copyright {new Date().getFullYear()} Fire Tower.</Mini>
          <Mini>All rights reserved.</Mini>
        </div>
      </TwoColumn>
    </footer>
  )
}

Footer.propTypes = {
  className: PropTypes.string,
}

export default Footer
