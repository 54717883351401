import cx from 'classnames'
import { Link, React, PropTypes, LoadImg } from 'library'

import s from './styles.module.scss'
import 'react-imported-component/macro'
const Img = LoadImg(() => import('../img/wordmark-blue.svg'))

const Wordmark = ({ className, to }) => {
  return (
    <Link to={to || '/'} className={cx(s.logo, className)}>
      <Img title="Fire Tower" />
    </Link>
  )
}

Wordmark.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
}

export default Wordmark
