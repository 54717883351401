import cx from 'classnames'
import { Link, React, PropTypes, LoadImg } from 'library'

import s from './styles.module.scss'
import 'react-imported-component/macro'
const Img = LoadImg(() => import('../img/symbol-brown.svg'))

const Symbol = ({ className, to, width }) => {
  return (
    <Link to={to || '/'} className={cx(s.logo, className)}>
      <Img
        alt=""
        title="Fire Tower"
        style={width && { width: width + 'rem' }}
      />
    </Link>
  )
}

Symbol.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  width: PropTypes.number,
}

export default Symbol
