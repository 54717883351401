import { PropTypes, Row, TextField, Form, Button, LoadingDots } from 'library'
import React from 'react'
import s from './styles.module.scss'

const EmailForm = ({ form }) => {
  const email = form.field('email')

  return (
    <Row className={s.row}>
      <TextField
        id="waitlist"
        type="email"
        required
        placeholder="Enter your email"
        {...email}
      />
      <Button submit>
        {email.submitted && !email.error ? <LoadingDots /> : 'Sign Up'}
      </Button>
    </Row>
  )
}

EmailForm.propTypes = {
  form: PropTypes.object,
}

export default Form(EmailForm)
