import React from 'react'
import PropTypes from 'prop-types'

import Logo from 'components/logos/Logo/color'
import cx from 'classnames'
import s from './styles.module.scss'
import { Row, Box, Link } from 'library'
const Nav = ({ children, className }) => {
  return (
    <Row
      className={cx(s.nav, className)}
      justify="space-between"
      align="flex-start"
      wrap>
      <Box align="flex-start">
        <Logo className={s.logo} />
        {children}
      </Box>
      <Box className={s.menu} align="flex-start">
        <Link
          onClick={() => {
            document
              .getElementById('about')
              .scrollIntoView({ behavior: 'smooth' })
          }}>
          About
        </Link>

        <Link to="/smokesignals">Blog: Smoke Signals</Link>
        <Link to="/login">Login</Link>
        <Link to="/signup">Join Waitlist</Link>
      </Box>
    </Row>
  )
}

Nav.propTypes = {
  className: PropTypes.string,
}

export default Nav
