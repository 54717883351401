import { React, PropTypes, Page } from 'library'
import classnames from 'classnames'

import image from './img/og-image.png'

// importing here so that doesn't apply to /app
import 'styles/global/index.scss'

const Site = ({ className, ...props }) => {
  return (
    <Page
      title="Fire Tower - Healthy teams grow healthy plants"
      description="Software for cannabis cultivation teams. Get your teams working on the same page with Fire Tower's grower-friendly app streamlined for day-to-day cultivation work."
      image={image}
      className={classnames(className)}
      {...props}
    />
  )
}

Site.propTypes = {
  className: PropTypes.string,
}

export default Site
